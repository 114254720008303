// JS FRONT OFFICE

// INPUT FILE CUSTOM
var inputFileItems = document.querySelectorAll(".input-file");

for (var i=0; i < inputFileItems.length; i++ ) {
    var fileInput  = inputFileItems[i].children[0],
        button     = inputFileItems[i].children[1],
        the_return = inputFileItems[i].children[2];

    button.addEventListener( "keydown", function( event ) {
        if ( event.keyCode == 13 || event.keyCode == 32 ) {
            this.previousElementSibling.focus();
        }
    });

    button.addEventListener( "click", function( event ) {
        this.previousElementSibling.focus();
        return false;
    });

    fileInput.addEventListener( "change", function( event ) {
        var this_return = this.nextElementSibling.nextElementSibling;
        this_return.innerHTML = this.value.replace('C:\\fakepath\\' , ' ');
    });
} // END

// Fonction qui affiche une notification en bas du header pendant 3 secondes
// type : ( string ) class qu'aura l'élément ajouté ( valid, error ou delete )
// message : ( string ) message affiché
function showMessage(type, message){
  var alert = $('<p>');
  alert.addClass('msg-info flash');
  alert.addClass(type);
  alert.html(message);

  $('body').append(alert);

  setTimeout(function(){
    alert.addClass('open');
  }, 100)

  setTimeout(function(){
    alert.removeClass('open');
  }, 3000)

  setTimeout(function(){
    alert.remove();
  }, 3500)
}

$(document).ready(function() {

    // RETOUR EN HAUT - créer balise #gotop
    $('#gotop').on('click',function() {  //
        $('html, body').animate({scrollTop:0}, 'slow');
        return false;
    });

    // BOUTON TOGGLE MAIN NAV
    $('.toggle-nav').click(function(){
        $(this).toggleClass("menu-open");
        $('.menu').toggleClass("showing-nav");
        return false;
    });

    // BOUTON TOGGLE SEARCH
    $('.toggle-search').click(function(){
        $(this).toggleClass("search-mobile-on");
        $('.form-search').toggleClass("search-open");
        return false;
    });

    // POPUP
    $('*[data-popup]').click(function(){
        $('body').addClass('mode-popup');
        $('#'+$(this).attr('data-popup')).addClass('open');
    })

    // CLOSE POPUP
    $('.close-popup').click(function(){
        $('body').removeClass('mode-popup');
        $(this).parent('.popup').removeClass('open');
    })

    // ONGLETS
    $('*[data-onglet]').click(function(){
        $('.nav-onglet > a').removeClass('actif');
        $('.onglet-content').removeClass('open');
        $(this).addClass('actif');
        $('#'+$(this).attr('data-onglet')).addClass('open');
    })

    // TOGGLE SECTION
    $('.toggle-section > .toggle-item').on('click', '> .toggle-launcher', function () {
        if ($(this).parent('.toggle-item').hasClass("open")) {
            $(this).closest('.toggle-section').find('> .toggle-item.open > .section-content').hide('fast');
            $(this).closest('.toggle-section').find('> .toggle-item.open').removeClass('open');
        } else {
            $(this).closest('.toggle-section').find('> .toggle-item.open > .section-content').hide('fast');
            $(this).closest('.toggle-section').find('> .toggle-item.open').removeClass('open');
            $(this).parent('.toggle-item').addClass('open');
            $(this).next().show('fast').css('display', 'flex');
        }
        return false;
    });

    // RECUP EMAIL NEWSLETTER POPUP
    $('[data-popup-news="inscrinews"]').on('click', function() {
        if($('#recup-email-news:valid').length != 0 ) {
            var email = $('#recup-email-news').val();
            $('#email-newsletter').attr('value', email);
            $('body').addClass('mode-popup');
            $('#'+$(this).attr('data-popup-news')).addClass('open');
        }
    })

    const menuHeight = $('.main-nav').offset().top;
    const homeHeight = $('.section-home').offset().top;
    const tutosHeight = $('.section-tutos').offset().top;
    const reglementHeight = $('.section-reglement').offset().top;

    $(window).scroll(function() {
        if(window.pageYOffset > (menuHeight + 20)) {
            $('.main-nav').addClass('fixed');
            $('body').addClass('header-fixed');
        }
        if(window.pageYOffset < menuHeight) {
            $('.main-nav').removeClass('fixed')
            $('body').removeClass('header-fixed');
        }

        if(window.pageYOffset < (homeHeight - 300)) {
            $('.menu li').removeClass('active');
        }

        if(window.pageYOffset > (homeHeight - 300)) {
            $('.menu li').removeClass('active');
            $('.link-home').addClass('active');
        }

        if(window.pageYOffset > (tutosHeight - 300)) {
            $('.menu li').removeClass('active');
            $('.link-tutos').addClass('active');
        }

        if(window.pageYOffset > (reglementHeight - 300)) {
            $('.menu li').removeClass('active');
            $('.link-reglement').addClass('active');
        }

    });

    /*
    $(".scrollTo").on("click", function() {
        var page = $(this).attr("href");
        var speed = 1000;
        $("html, body").animate({ scrollTop: ($(page).offset().top) }, speed);
        return false;
    });

    */

    $('.menu li a').click(function(){
        $('.toggle-nav').removeClass("menu-open");
        $('.menu').removeClass("showing-nav");
    });

}); // FIN DOCUMENT READY
